<template>
  <div class="reviewwordlearning">
    <div class="leftnav">
      <step-secont :active="active"/>
    </div>
    <audio ref="audio" autoplay controls :src="audioVoice==='y' ? currentObj.ygfy : currentObj.mgfy" style="width: 0;height: 0;position: absolute;top: 0;">您的浏览器不支持 audio 标签。</audio>
    <main v-if="type == 1">
      <p class="title"><span>NEWS WORDS AND EXPRESSIONS</span></p>
      <p class="footer" @click="start">已熟悉，开始学习</p>
      <div class="wordbox">
        <div class="wordList">
          <div class="wordtext" v-for="(item, index) in dcdyList" :key="index" @click="textclick(item,index)">
            <img src="../../../assets/student/bottomright.png" alt="" v-if="currentIndex == index">
            <p><span>{{item.nr}}</span> <i style="font-family: Arial, Helvetica, sans-serif;font-style:normal;">{{item[audioVoice + 'gyb'] ? item[audioVoice + 'gyb'].replace(/\[|]/ig, "/") : '&nbsp;'}}</i> {{item.cy}}</p>
          </div>
        </div>
      </div>
    </main>
    <main v-if="type == 2">
      <div class="type2box">
        <div class="type2title">
          <span>{{currentObj.nr}}</span><img style="cursor: pointer;" @click="play" src="../../../assets/student/englishplay.png" alt="">
        </div>
        <div class="yb boxitem">
          <span class="label">{{ (audioVoice === 'y' ? '英':'美') + '式音标：'}}</span>
          <div class="value" style="font-family: Times New Roman, Helvetica, sans-serif;">{{audioVoice === 'y' ? currentObj.ygyb : currentObj.mgyb}}</div>
        </div>
        <div class="cy boxitem">
          <span class="label">词 &nbsp;义：</span>
          <div class="value" style="line-height:2vw;">{{currentObj.cy}}</div>
        </div>
        <div class="sjf boxitem">
          <span class="label">速 记 法：</span>
          <div class="value">{{currentObj.sjf || '暂无速记法'}}</div>
        </div>
      </div>
      <div class="footer2">
        <div @click="type = 1">学习生词和短语</div>
        <div @click="start">课文学习</div>
      </div>
    </main>
  </div>
  <Header :header_data="header_data" :noClicking="true" />
</template>
<script>
import Header from "../components/zjfheader.vue";
import { useStore } from 'vuex'
import { onMounted } from "vue-demi";
import { getCurrentInstance, nextTick, reactive, toRefs, ref, onBeforeUnmount } from "vue";
import { getEnglishText, getEnglishTextWord, saveMyBreakpoint } from '@/api/student'
import { ElMessage } from 'element-plus';
import StepSecont from '../components/stepSecont.vue';
export default {
  components: {
    Header,
    StepSecont
  },
  setup() {
    const store = useStore()
    const { appContext } = getCurrentInstance();
    const that = appContext.config.globalProperties;
    const data = reactive({
      currentIndex: 0, // 学单词页面前面箭头显示在哪个索引前
      type: 1, // 控制步骤
      currentObj: {},
      yworfy: true, // 原文，或者翻译
      hightlight: null, // 句子高亮
      header_data: [],
      dcdyList: [], //单词短语list
      audioVoice: '',
      active: 1
    });
    onMounted(() => {
      data.audioVoice = localStorage.getItem(`sound_${store.state.userInfo.realname}`) || 'y'
      // 获取课文发音
      getEnglishText({kwid: that.$route.query.kwid}).then(res => {
        if(res.success) {
          data.header_data = [
            { title: "图谱英语 — ", path: "newEnglish" },
            { title: that.$route.query.zmc+" — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
            { title: that.$route.query.dymc+" — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
            { title: res.result.kwmc, path: "" },
          ]
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
      getEnglishTextWord({kwid: that.$route.query.kwid}).then(res => {
        if(res.success) {
          if(res.result.length) {
            data.dcdyList = res.result
          }
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    });
    // 点击单词进入单词详情页
    const textclick = (row,index) => {
      // // 截取单词，抽出单词，词义，拆分项，音标
      data.currentObj = row
      data.currentIndex = index
      data.type = 2
      nextTick(() => {
        audio.value.play()
      })
    }
    // 已熟悉开始学习/课文学习
    const start = () => {
      that.$router.replace({ name: 'reviewtextlearning', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
    }
    const play =() => {
      audio.value.play()
    }
    const audio = ref(null);
    return {
      ...toRefs(data),
      textclick,
      start,
      play,
      audio,
      onBeforeUnmount
    };
  },
};
</script>
<style lang="scss" scoped>
.reviewwordlearning {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/mnemonic.png") no-repeat;
  background-size: 100% 100%;
  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 9vw;
    z-index: 3;
  }
  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner2.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding-top: 3vw;
    .title {
      font-size: 1.8vw;
      color: #0972E7;
      text-align: center;
      line-height: 1.5vw;
      span {
        display: inline-block;
        position: relative;
        font-family: 'Times New Roman';
      }
      // span::before {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 0.2vw;
      //   // background: rgba(249, 164, 80, 0.6);
      //   position: absolute;
      //   bottom: 0.1vw;
      // }
    }
    .footer {
      width: 12.96vw;
      height: 4.11vw;
      background: url("../../../assets/student/wordysxbtn.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 4.11vw;
      font-size: 1.4vw;
      color: #fff;
      position: absolute;
      bottom: 1.5vw;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .footer2 {
      text-align: center;
      line-height: 4.11vw;
      font-size: 1.4vw;
      color: #fff;
      position: absolute;
      bottom: 1.5vw;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      div{
        width: 12.96vw;
        height: 4.11vw;
        background: url("../../../assets/student/wordysxbtn.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      div:nth-child(1) {
        margin-right: 2vw;
      }
    }
    .footer3 {
      text-align: center;
      line-height: 4.11vw;
      font-size: 1.4vw;
      color: #fff;
      position: absolute;
      bottom: 1.5vw;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      .div {
        width: 12.96vw;
        height: 4.11vw;
        line-height: 4.11vw;
        background: url("../../../assets/student/wordysxbtn.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        margin-right: 2vw;
      }
      .div:nth-child(1) {
        img {
          vertical-align: middle;
          display: inline-block;
          width: 2vw;
          height: 2vw;
        }
      }
      .div:nth-child(2) {
        background: url("../../../assets/student/writebtn.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        img:nth-child(1) {
          left: 10%;
        }
        img:nth-child(2) {
          right: 10%;
        }
        .seetext {
          width: 9.218vw;
          height: 4.11vw;
          position: absolute;
          transition: 0.5s;
          img {
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
          }
          p {
            position: absolute;
            width: 100%;
            text-align: center;
          }
        }
        .seetextfy {
          left: 0;
        }
        .seetextyw {
          left: 30%;
        }
      }
    }
    .wordbox {
      width: 100%;
      height: 70%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow-y: auto;
    }
    .wordList {
      width: 80%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
      .wordtext {
        width: 50%;
        float: left;
        line-height: 4.5vw;
        cursor: pointer;
        position: relative;
        font-size: 2vw;
        z-index: 2;
        img{
          display: block;
          position: absolute;
          top: 1vw;
          left: -4vw;
          transform: rotate(-10deg);
          width: 2vw;
          height: 2vw;
        }
        p{
          width: 90%;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          span {
            position: inherit;
            display: inline;
            font-family: 'Times New Roman', Times, serif;
          }
          i {
            color: #0972E7;
            font-family: 'Times New Roman', Times, serif;
          }
        }
      }
      span {
        position: absolute;
        top: 1vw;
        left: 2vw;
        display: block;
        width: 3vw;
        height: 3.5vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .type2box {
      width: 54%;
      height: 100%;
      margin: auto;
      box-sizing: border-box;
      padding-top: 2vw;
      .type2title{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 1vw;
        span {
          font-size: 2.5vw;
          margin-right: 1vw;
          display: inline-block;
          margin-bottom: .4vw;
        }
        img{
          display: inline-block;
          width: 3.281vw;
          height: 3.281vw;
          vertical-align: middle;
        }
      }
      .boxitem {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 1.5vw;
        .label{
          font-size: 1.7vw;
          color: #333333;
          // margin: auto;
          // float: left;
          width: 8.59vw;
          vertical-align: middle
        }
        .value{
          // float: left;
          width: calc(100% - 165px);
          font-size: 1.3vw;
          white-space: pre-wrap;
          vertical-align: middle;
          line-height: 2.5vw;
        }
        .value:first-child{
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.reviewwordlearning {
  ::-webkit-scrollbar {
    width: .6vw;
    margin-right: .3vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  // 修改popover样式
  .el-popper.is-light {
    top: -10px !important;
  }
}
.el-popover.el-popper{
  border: 2px solid #2D86EA !important;
  padding: 10px !important;
}
.el-popper.is-light .el-popper__arrow::before {
  border-top: 2px solid #2D86EA;
  border-left: 2px solid #2D86EA;
}
</style>